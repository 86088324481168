import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import SingleHero from "components/SingleHero"
import Breadcrumbs from "components/Breadcrumbs"
import SubpageBack from "components/SubpageBack"

import { CookiesPolicy } from "page_components/legals"

const Cookies = ({ data, location }) => {
  const pageData = data?.allWpPage?.nodes?.[0]
  const title = pageData?.title
  const content = pageData?.content

  return (
    <Layout
      location={location}
      seo={{
        title,
      }}
    >
      <SingleHero title={title} />
      <Breadcrumbs title={title} />
      <CookiesPolicy content={content} />
      <SubpageBack />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo3MzA=" } }) {
      nodes {
        id
        title
        content
      }
    }
  }
`

export default Cookies
